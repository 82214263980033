<template>
    <div class="">
        <div class="flex justify-space-between">
            <div class="box self-flex-end"><h2 class="mv-0">CREATE WAIVER</h2></div>
            <div class="box"><el-button @click="$onCommandParams('waivers')">BACK</el-button></div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

          <div class="flex">
            <div class="box grow">
                <el-form label-position="left" :rules="rules" label-width="160px" :model="form" size="small" ref="form">
                    <el-form-item label="First name" prop="first">
                        <el-input v-model="form.first" type="text" name="fname" id="frmNameA" autocomplete="given-name"></el-input>
                    </el-form-item>
                    <el-form-item label="Surname" prop="surname">
                        <el-input v-model="form.surname" type="text" name="lname" id="frmNameA" autocomplete="family-name"></el-input>
                    </el-form-item>
                    <el-form-item label="Phone" prop="phone">
                        <el-input v-model="form.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="Email" prop="email">
                        <el-input v-model="form.email" type="email" name="email" id="frmEmailA" autocomplete="email"></el-input>
                    </el-form-item>
                    <el-form-item label="Group">
                        <el-select :popper-append-to-body="false" size="small" v-model="form.group" type="tel" name="phone" id="frmPhoneNumA" autocomplete="tel">
                          <el-option
                            v-for="item in groupsForSelect"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Birthday" prop="birthday">
                        <el-date-picker
                          :append-to-body="false"
                          type="date"
                          v-model="form.birthday">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="Lesson">
                        <el-input-number :controls="false" v-model="form.lesson" :disabled="true"></el-input-number>
                    </el-form-item>
                    <el-form-item label="Subscription">
                        <el-input-number :controls="false" v-model="form.subscription" :disabled="true"></el-input-number>
                    </el-form-item>
                    <el-form-item label="Comment">
                        <el-input type="textarea" v-model="form.comment"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" @click="submit('form')">Submit</el-button>
                    </el-form-item>
                </el-form>
            </div>
          </div>

    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Waiver',

  data () {
    return {
      form: {
        first: '',
        surname: '',
        email: '',
        phone: '',
        group: '',
        birthday: '',
        lesson: 0,
        subscription: 0,
        comment: ''
      },
      rules: {
        first: [
          { required: true, message: 'Please input first name', trigger: 'blur' }
        ],
        surname: [
          { required: true, message: 'Please input surname', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Please input email address', trigger: 'blur' },
          { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
        ],
        phone: [
          { required: true, message: 'Please input phone number', trigger: 'blur' }
        ]
      }
    }
  },

  computed: {
    ...mapState({
      groups: state => state.group.groups
    }),
    ...mapGetters([
      'groupsForSelect'
    ])
  },

  methods: {
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch('addClient', this.form)
            .then(() => {
              // this.$notify({
              //   title: 'Success',
              //   message: 'New member created successfully',
              //   type: 'success',
              //   position: 'top-right'
              // })
              this.$message({
                showClose: true,
                message: 'New member created successfully',
                type: 'success'
              })
            })
            .then(() => {
              this.$onCommandParams('waivers')
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">

</style>
